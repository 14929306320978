































































































import {Component, Prop} from 'vue-property-decorator';
import AuthViewBase from "@/views/AuthViewBase";
import LoadingHandler from '@/utils/loading-handler';

import SummaryPanel from '@/views/spec/companies/components/spec-components/show/SummaryPanel.vue';
import {CompanyRepository} from "@/repositories/company-repository";
import SideNavItem from "@/views/spec/companies/components/spec-components/show/components/SideNavItem.vue";
import SpecSelfSubmissionEntity from "@/entities/specs/spec-self-submission-entity";
import SpecSelfSubmissionRepository, {ISpecSelfSubmissionCreateRequest} from "@/repositories/spec/company/spec-self-submission-repository";
import SpecRepository, {
  ImportDataType,
  ImportResponse,
  ExportEventArgs,
  IExportRequest
} from "@/repositories/spec/company/spec-repository";
import SpecImportMenu from "@/views/spec/companies/components/SpecImportMenu.vue";
import {IFileUpload} from "@/contracts/i-file";
import SelfSubmissionModal from '@/views/spec/companies/self-submissions/create/components/SelfSubmissionModal.vue';

import SpecCompareModal from "@/views/spec/companies/components/SpecCompareModal.vue";
import {ApprovalService} from "@/services/approval-service";
import CompanyEntity from "@/entities/company-entity";
import SpecAcceptanceRepository from "@/repositories/spec/company/spec-acceptance-repository";

import { help as PopoverText } from '@/lang/help/spec-create';
import SpecExportMenu from "@/views/spec/companies/components/SpecExportMenu.vue";
import {saveDownloadedBlob} from "@/repositories/repository-base";
import SpecImExportError from "@/entities/specs/spec-im-export-error";
import EditableSlot, {isEditable} from "@/views/components/Department/EditableSlot.vue";
import IconSelfSubmissionAlert from "@/components/Icons/IconSelfSubmissionAlert.vue";
import { ComparisonEntityType } from '../../spec-comparisons/comparison-entity-type';
import {AccessLogRepository} from "@/repositories/access-log-repository";
import ApprovalSection from "@/views/spec/companies/components/Approval/ApprovalSection.vue";
import {SpecAcceptanceSpecApprovalRepository} from "@/repositories/spec/company/approval-request-repository";
import EditableOnApprovalSlot from "@/views/components/Approval/EditableOnApprovalSlot.vue";
import EditableOnApprovalLink from "@/views/components/Approval/EditableOnApprovalLink.vue";

@Component({
  components: {
    EditableOnApprovalLink,
    EditableOnApprovalSlot,
    ApprovalSection,
    IconSelfSubmissionAlert,
    EditableSlot,
    SpecExportMenu,
    SummaryPanel,
    SideNavItem,
    SpecImportMenu,
    SelfSubmissionModal,
    SpecCompareModal,
  },
})
export default class SelfSubmissionShow extends AuthViewBase {
  @Prop({required: true}) private selfSubmissionId!:number;

  private selfSubmission:SpecSelfSubmissionEntity = null as any;
  private versions:SpecSelfSubmissionEntity[] = [];
  private company:CompanyEntity = null as any;

  private selectedSelfSubmissionId = Number(this.selfSubmissionId);

  private readonly PopoverText = PopoverText;
  private readonly ComparisonEntityType = ComparisonEntityType;
  private compareModalVisible = false;

  private initialized: boolean = false;

  private get approvalRepository() {
    return new SpecAcceptanceSpecApprovalRepository(this.company!.id, this.selfSubmission.specAcceptanceSpec.id);
  }
  private get needsMoreApproval(): boolean {
    return (new ApprovalService(this.company!, false)).needsMoreApproval(this.selfSubmission.specAcceptanceSpec.approvalRequest);
  }
  private get approvalSettingEnabled(): boolean {
    return (new ApprovalService(this.company!, false)).approvalSettingEnabled();
  }
  private get isEditable() {
    return isEditable(this.$auth.user, this.company!, this.selfSubmission.meta);
  }

  private created() {
    this.load();
  }
  private load() {
    LoadingHandler(async () => {
      return Promise.all([
        (new CompanyRepository()).findById(this.companyId).then(com => {
          this.company = com;
        }),
        (new SpecSelfSubmissionRepository(this.companyId)).find(this.selfSubmissionId).then(res => {
          this.selfSubmission = res;
          if (this.selfSubmission.specAcceptanceSpec) {
            (new AccessLogRepository(this.companyId)).logAcceptanceSpec(this.selfSubmission.specAcceptanceSpec.id);
          }
        }),
        (new SpecSelfSubmissionRepository(this.companyId)).getVersions(this.selfSubmissionId).then(res => {
          this.versions = res.sort((a,b) => a.id - b.id);
        }),
      ]);
    }).then(() => {
      this.initialized = true;
    });
  }

  private onChangeVersion() {
    this.$router.push({name: 'self-submission.show', params: {selfSubmissionId: this.selectedSelfSubmissionId} as any}).then(() => {
      this.load();
    });
  }

  private async accept() {
    await LoadingHandler(async () => {
      await (new SpecAcceptanceRepository(this.companyId)).acceptSpec(this.selfSubmission!.specAcceptanceSpec.id);
    });
    this.$message({type: 'success', message: this.$t(`受領しました`)});
    await this.load();
  }

  // Export
  private async validateExport(req:ExportEventArgs) {
    return await (new SpecRepository(this.companyId)).validateExportSpec(this.selfSubmission!.spec.id, req);
  }
  private modifySpecWithError(error:SpecImExportError) {
    this.$router.push({
      name: 'self-submission.edit.import',
      params: {selfSubmissionId: this.selectedSelfSubmissionId.toString(), convertingError: error as any}
    });
  }
  private async exportSpec(args:IExportRequest) {
    args.arg1 = args.arg1 || this.company!.name;

    return LoadingHandler(async () => {
      const spec = this.selfSubmission!.spec;
      const res = await (new SpecRepository(this.companyId)).exportSpec(spec.id, args);
      saveDownloadedBlob(res, `${spec.name.replaceAll('.', '_')}`);
    }, 180000, {text: `${this.$t('出力しています')}（${this.$t('この処理は数十秒かかる場合があります')}）`});
  }

  // Import
  private submissionModalVisible = false;
  private selectedImportFile:any = { type: null, file: null, params: {} };

  private onImportFileSelected(type:ImportDataType, file:IFileUpload|IFileUpload[], params?:object) {
    this.selectedImportFile = { type: type, file: file, params: params };
    this.submissionModalVisible = true;
  }
  private async onSubmitImportFile(submissionRequest:ISpecSelfSubmissionCreateRequest) {
    LoadingHandler(() => {
      return (new SpecSelfSubmissionRepository(this.companyId))
        .reviseByImporting(this.selfSubmissionId, this.selectedImportFile.type, this.selectedImportFile.file, submissionRequest, this.selectedImportFile.params);
    }, 180000, {text: this.$t('この処理は数十秒かかる場合があります')}).then((res:ImportResponse) => {
      this.submissionModalVisible = false;

      if (res.success) {
        const append = this.company!.approvalSettingEnabled(false) ? this.$t('（承認が必要です）') : '';
        this.$message({type: 'success', message: (this.$t('のデータ取り込みが完了しました', [res.data.name]))+ append});
        this.selectedSelfSubmissionId = (res.data as {id}).id;
        this.onChangeVersion();
      } else {
        this.$message({type: 'warning', message: this.$t('確認が必要な項目があります')});
        this.$router.push({
          name: 'self-submission.edit.import',
          params: {selfSubmissionId: this.selectedSelfSubmissionId.toString(), importingSpec: res.data as any, convertingError: res.error as any}, query: {'view': 'ingredient'}
        });
      }

    });
  }

  private async approvalUpdated() {
    this.load();
    await this.$emit("updated");
  }

  private get sellerRoute() {
   if(!this.selfSubmission.spec.seller.referencePartnerId) return undefined;
   return {name: 'mypage.company.partner.edit', params: { partnerId: this.selfSubmission.spec.seller.referencePartnerId } };
  }
}
