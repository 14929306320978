








import {Component, Prop, Vue} from 'vue-property-decorator';
import {i18n} from "@/bootstraps/locale";

@Component({
  components: {
  },
})
export default class Manufacture extends Vue {
  @Prop({required: true}) private value!:any;
  @Prop({default: i18n.t('有り')}) private trueLabel!:string;
  @Prop({default: i18n.t('無し')}) private falseLabel!:string;
  @Prop({default: true}) private trueValue!:any;
  @Prop({default: false}) private falseValue!:any;
  @Prop({default: true}) private full!:boolean;
  @Prop({required: false}) private size!:'small'|undefined;
  @Prop({default: true}) private clearable!:boolean;

  private get model() {
    return this.value;
  }
  private set model(value: any) {
    this.$emit('input', value);
  }
}
