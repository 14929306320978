


















































































import { Vue, Component, Prop} from 'vue-property-decorator';
import { MakerTypeDict } from '@/entities/specs/partner-entity';
import ShowBase from "@/views/spec/companies/components/spec-components/show/ShowBase";
import CustomValueShowSection from "@/views/spec/companies/components/spec-components/show/components/CustomValueShowSection.vue";
import { SpecCustomValueCategory } from '@/entities/specs/spec-custom-value-entity';
import AttachmentImage from "@/views/spec/companies/components/spec-components/show/components/AttachmentImage.vue";
import SpecEntity from "@/entities/specs/spec-entity";

@Component({
  components: {
    AttachmentImage,
    CustomValueShowSection,
  }
})
export default class extends ShowBase {
  @Prop({required: false}) protected sellerRoute?:number;

  private readonly MakerTypeDict = MakerTypeDict;
  private readonly SpecCustomValueCategory = SpecCustomValueCategory;

  // TODO: makerがnullableじゃなくなったので、データ補完後に消す
  private get maker() {
    return this.spec.maker || this.spec.seller;
  }
}
