
















































































































import {Component, Prop} from 'vue-property-decorator';
import AuthViewBase from "@/views/AuthViewBase";
import LoadingHandler from '@/utils/loading-handler';
import HistoryModal from "@/views/spec/companies/components/HistoryModal.vue";

import SummaryPanel from '@/views/spec/companies/components/spec-components/show/SummaryPanel.vue';
import {ValidateWithScroll} from "@/utils/validation-handler";
import {SpecShareDetailEntity, SpecShareEntity, SpecShareStatus,} from "@/entities/specs/spec-share-entity";
import {ValidatorRules as SpecShareAcceptanceValidatorRules} from '@/entities/specs/spec-acceptance-entity';
import {
  ISpecShareMessageRequest,
  ValidatorRules as SpecShareMessageValidatorRules
} from "@/entities/specs/spec-share-message-entity";
import SpecSubmissionEntity from "@/entities/specs/spec-submission-entity";
import SideNavItem from "@/views/spec/companies/components/spec-components/show/components/SideNavItem.vue";
import SpecShareForReceiverRepository from "@/repositories/spec/company/spec-share-for-receiver-repository";
import CompanyEntity from "@/entities/company-entity";
import {CompanyRepository} from "@/repositories/company-repository";
import {ApprovalService} from "@/services/approval-service";

import { help as PopoverText } from '@/lang/help/spec-create';
import {IAcceptanceInfoRequest} from "@/repositories/spec/company/spec-acceptance-repository";

import TagSelect from "@/components/TagSelect.vue";
import {CompanyResourceTagType} from "@/entities/company-entity";
import MemberSelect from "@/views/spec/companies/components/MemberSelect.vue";
import ResourceDepartmentSelectSection from "@/views/components/Department/ResourceDepartmentSelectSection.vue";
import EditableSlot from "@/views/components/Department/EditableSlot.vue";
import ApprovalSection from "@/views/spec/companies/components/Approval/ApprovalSection.vue";
import SpecSubmissionApprovalRepository from "@/repositories/spec/company/approval-request-repository";
import SimpleFormItem from "@/components/Form/SimpleFormItem.vue";

@Component({
  components: {
    SimpleFormItem,
    ApprovalSection,
    EditableSlot,
    ResourceDepartmentSelectSection,
    SummaryPanel,
    HistoryModal,
    SideNavItem,
    TagSelect,
    MemberSelect
  }
})
export default class extends AuthViewBase {
  @Prop({required: true}) private specShareId!:number;
  @Prop({required: true}) private submissionId!:number;

  private company:CompanyEntity = null as any;
  private specShare:SpecShareDetailEntity = null as any;
  private submissions:SpecSubmissionEntity[] = null as any;
  private submission:SpecSubmissionEntity = null as any;

  private selectedSubmissionId = Number(this.submissionId);

  private initialized: boolean = false;

  private readonly PopoverText = PopoverText;
  private readonly CompanyResourceTagType = CompanyResourceTagType;

  private historyModalVisible:boolean = false;
  private rfcModal = {
    visible: false,
    data: { department: '', operatorName: this.$auth.user.name, comment: '' } as ISpecShareMessageRequest,
    rules: SpecShareMessageValidatorRules
  }
  private acceptanceModal = {
    visible: false,
    data: {
      receiverIntraCode: '',
      receiverMemo: '',
      operatorId: this.$auth.user.id, // 本当は依頼したユーザーのIDを入れたいが、面倒そうなので後回し
      departmentIds: this.$auth.user.departments.map(d => d.id),
      tags: [],
    } as IAcceptanceInfoRequest,
    rules: SpecShareAcceptanceValidatorRules,
  }

  private get actionVisible() {
    return this.specShare!.status === SpecShareStatus.SUBMITTED &&
      Number(this.submissionId) === this.specShare!.latestSubmissionId;
  }

  private get approvalRepository() {
    return new SpecSubmissionApprovalRepository(this.company!.id, this.specShareId, this.submission!.id);
  }

  private async approvalUpdated() {
    this.load();
    await this.$emit("updated");
  }

  private created() {
    this.load();
  }
  private async load() {
    await LoadingHandler(async () => {
      const data = await (new SpecShareForReceiverRepository(this.companyId)).findSpecShare(this.specShareId);
      const submission = data.submissions.find(s => s.id === Number(this.submissionId));
      if (!submission) {
        this.$message({type: 'error', message: this.$t("URLが見つかりませんでした") });
        return;
      }
      this.submissions = data.submissions.sort((a,b) => a.id - b.id);
      this.specShare = data;
      this.submission = submission;

      this.company = await (new CompanyRepository()).findById(this.companyId);

      this.initialized = true;
    });
  }

  private get needsApproval(): boolean {
    return (new ApprovalService(this.company!, false)).needsMoreApproval(this.submission.approvalRequest);
  }
  private get hasApprovalSetting(): boolean {
    return (new ApprovalService(this.company!, false)).approvalSettingEnabled();
  }

  private onChangeVersion() {
    this.$router.push({name: 'receive.show', params: {submissionId: this.selectedSubmissionId} as any}).then(() => {
      this.load();
    });
  }
  private formatDate(val, format = 'yyyy/MM/dd HH:mm'): string {
    return this.$options.filters!.formatDate(val, format);
  }

  private async submitRfc() {
    if (!(await ValidateWithScroll(this.$refs.rfcForm as any))) return;

    LoadingHandler(async () => {
      await (new SpecShareForReceiverRepository(this.companyId)).setStatusAsRfc(this.specShareId, this.rfcModal.data);

      this.$router.push({name: 'receive.index'}).then(() => {
        this.$message({type: 'success', message: this.$t(`${this.submission!.spec.name}を差戻しました`)});
      });
    });
  }

  private startAcceptance() {
    this.acceptanceModal.visible = true;
  }

  private async submitAcceptance() {
    if (!(await ValidateWithScroll(this.$refs.acceptanceForm as any))) return;

    LoadingHandler(async () => {
      const res = await (new SpecShareForReceiverRepository(this.companyId)).setStatusAsAccepted(this.specShareId, this.acceptanceModal.data);
      const message = `${this.submission!.spec.name}を受領しました`;
      const append = res.isIngredientCreationSkippedByAmountHidden ? this.$t('（配合量が非公開のため、食品表示の原材料が作成されませんでした）') : '';
      this.$router.push({name: 'receive.index'}).then(() => {
        this.$message({type: 'success', message: message + append });
      });
    });
  }
}
