import {Component, Prop, Watch, Vue} from "vue-property-decorator";
import CreatePageBase from "@/views/label/companies/CreatePageBase";
import {ValidateWithScroll} from "@/utils/validation-handler";
import {isComponentHasError} from "@/views/spec/companies/components/spec-components/create/components/component-error";
import Basic from "@/views/spec/companies/components/spec-components/create/Basic.vue";
import Company from "@/views/spec/companies/components/spec-components/create/Company.vue";
import Ingredient from "@/views/spec/companies/components/spec-components/create/Ingredient.vue";
import Label from "@/views/spec/companies/components/spec-components/create/Label.vue";
import Nutrition from "@/views/spec/companies/components/spec-components/create/Nutrition.vue";
import Package from "@/views/spec/companies/components/spec-components/create/Package.vue";
import Manufacture from "@/views/spec/companies/components/spec-components/create/Manufacture.vue";
import SpecEntity, {SpecCreateBaseEntity} from "@/entities/specs/spec-entity";
import {i18n} from "@/bootstraps/locale";
import SpecIngredientEntity from "@/entities/specs/spec-ingredient-entity";
import SpecIngredientCollection from "@/entities/specs/spec-ingredient-collection";
import {SpecCustomValueCategory} from "@/entities/specs/spec-custom-value-entity";

export type ViewType = 'basic'|'company'|'ingredient'|'label-display'|'nutrition'|'package'|'manufacture';

export const Views:{name: ViewType; label: string; component: any; customValueCategory?: SpecCustomValueCategory }[] = [
  {name: 'basic', label: i18n.t('基本'), component: Basic, customValueCategory: SpecCustomValueCategory.Basic },
  {name: 'company', label: i18n.t('企業'), component: Company, customValueCategory: SpecCustomValueCategory.Company },
  {name: 'ingredient', label: i18n.t('原材料'), component: Ingredient, customValueCategory: SpecCustomValueCategory.Ingredient},
  {name: 'label-display', label: i18n.t('一括表示'), component: Label },
  {name: 'nutrition', label: i18n.t('栄養成分'), component: Nutrition, customValueCategory: SpecCustomValueCategory.Nutrition },
  {name: 'package', label: i18n.t('包材'), component: Package, customValueCategory: SpecCustomValueCategory.Package },
  {name: 'manufacture', label: i18n.t('製造'), component: Manufacture, customValueCategory: SpecCustomValueCategory.Manufacture },
]
export const PageComponents = Views.reduce((walk, val) => {
  walk[val.name] = val.component;
  return walk;
}, {});

@Component
export default class CreateSpecPageBase extends CreatePageBase {
  protected activeTab:ViewType = 'basic';
  protected model:any|null;

  protected readonly views = Views;

  protected initialized: boolean = false;
  protected isAllLoaded:boolean = false;

  protected get spec(): SpecCreateBaseEntity | null {
    return this.model;
  }

  @Watch('model', {deep: true})
  private watchModel(val, oldVal) {
    this.onDataChanged(val, oldVal);
  }
  protected onDataChanged(val, oldVal) {
    if(this.isAllLoaded) {
      this.isAnyChanged = true;
    }
  }
  private initializedComponents:string[] = [];
  protected onComponentInitialized(name) {
    this.initializedComponents.push(name);
    if(Object.keys(PageComponents).length === this.initializedComponents.length) {
      this.$nextTick(() => {
        this.isAllLoaded = true;
        this.onAllTabLoaded();
      });
    }
  }
  protected onAllTabLoaded() { }

  // タブのhasError計算用
  protected componentsWithError:ViewType[] = [];
  private getComponentsWithError(): ViewType[] {
    const hasError = (component:string) => {
      if (!this.$refs[component] || !this.$refs[component][0]) return false;
      return isComponentHasError(this.$refs[component]);
    };
    return this.views.filter(v => hasError(v.name)).map(v => v.name);
  }
  protected async onValidated() {
    await this.$nextTick();
    this.componentsWithError.splice(0);
    this.componentsWithError.push(...this.getComponentsWithError());
  }

  public async validate($form:any): Promise<boolean> {
    $form.clearValidate();

    if (!(await ValidateWithScroll($form))) {
      await this.onValidated();
      this.activeTab = this.componentsWithError[0];
      this.$message( {type: 'error', message: `入力漏れの箇所があります。赤くハイライトされたタブ、フォームを修正して再度保存してください。（まだ保存されていません）`});
      return false;
    }

    const found = this.spec!.ingredients.find(ing => ing.isInvalid);
    if (!!found) {
      const collection = new SpecIngredientCollection(this.spec!, this.spec!.ingredientsNested);
      const level = collection.getLevel(found);
      this.$message( {type: 'error', message: `原材料の階層番号'${level}'に未入力の項目があります`});
      this.activeTab = 'ingredient';
      return false;
    }

    return this.additionalValidate();
  }

  // TODO: 該当行にエラーを表示したい
  protected additionalValidate(): boolean {
    // 製剤なのに直下に添加物がない場合を探す
    const validateIngredient = (ings:SpecIngredientEntity[]): boolean => {
      const formulations = ings.filter(ing => ing.isAdditiveFormulation);
      if (formulations.length === 0) return true;
      return formulations.every(ing => {
        return ing.children.some(child => child.isAdditive && validateIngredient(ing.children));
      });
    };

    if (!validateIngredient(this.model!.ingredientsNested)) {
      this.$message({type: 'error', message: this.$t('原材料タブ内：製剤の下の階層には、添加物を設定する必要があります。') });
      this.activeTab = 'ingredient';
      return false;
    }
    return true;
  }

  protected setAnotherSpec(spec:SpecCreateBaseEntity ) {
    this.isAllLoaded = false;
    this.initialized = false;
    this.isAnyChanged = false;
    this.initializedComponents = [];

    this.model = spec;
    this.$nextTick(() => {
      this.initialized = true;
    });
  }

  protected onDraftSaved() {
    this.isAllLoaded = false;
    this.isAnyChanged = false;
  }

  public importSpec(spec:SpecCreateBaseEntity, isUndoing = false) {
    if (!isUndoing) {
      this.modelHistory.push(this.model);
    }
    this.setAnotherSpec(spec);
  }
  protected undo() {
    // 引用後、別の箇所を操作しているときにCtrlZを押してしまうと、引用前まで戻ってします。
    // 挙動をカバーできるようになるまで一旦塩漬け
    // this.importSpec(this.modelHistory.pop()!, true);
  }
}
