





































































import { Vue, Component, Prop} from 'vue-property-decorator';

import {ValidatorRules as SpecAcceptanceValidatorRules} from "@/entities/specs/spec-acceptance-entity";
import Row from "@/views/spec/companies/components/spec-components/show/components/Row.vue";
import {ValidateWithScroll} from "@/utils/validation-handler";
import LoadingHandler from "@/utils/loading-handler";
import SpecSelfSubmissionEntity  from "@/entities/specs/spec-self-submission-entity";
import SpecSelfSubmitterRepository from "@/repositories/spec/company/spec-self-submitter-repository";
import SpecSelfSubmitterEntity from "@/entities/specs/spec-self-submitter-entity";
import SpecSelfSubmissionRepository, {ISpecSelfSubmissionUpdateRequest} from "@/repositories/spec/company/spec-self-submission-repository";
import {requiredRule, typeNumberRule} from "@/utils/validation-rules";
import SpecRepository from "@/repositories/spec/company/spec-repository";
import AuditEntity from "@/entities/audit-entity";
import AuditSection from "@/views/spec/companies/components/spec-components/show/components/AuditSection.vue";
import CustomValueShowSection from "@/views/spec/companies/components/spec-components/show/components/CustomValueShowSection.vue";
import cloneDeep from "lodash/cloneDeep";
import CompanyEntity from "@/entities/company-entity";

import { help as PopoverText } from '@/lang/help/spec-create';

import AcceptanceIntraForm from "@/views/spec/companies/components/AcceptanceIntraForm.vue";
import AcceptanceIntraBasicSection from "@/views/spec/companies/components/AcceptanceIntraBasicSection.vue";
import AttachmentImage from "@/views/spec/companies/components/spec-components/show/components/AttachmentImage.vue";
import InputAttachment from "@/views/spec/companies/components/spec-components/create/components/InputAttachment.vue";
import {isCustomValueEmpty} from "@/entities/interfaces/i-custom-value";
import SpecAcceptanceRepository from "@/repositories/spec/company/spec-acceptance-repository";
import SummaryPanel from "@/views/spec/companies/components/spec-components/show/SummaryPanel.vue";
import IconOpen from "@/components/Icons/IconOpen.vue";

@Component({
  components: {
    IconOpen,
    SummaryPanel,
    InputAttachment,
    AttachmentImage,
    Row,
    AuditSection,
    CustomValueShowSection,
    AcceptanceIntraForm,
    AcceptanceIntraBasicSection
  }
})
export default class extends Vue {
  @Prop({required: true}) private selfSubmission!:SpecSelfSubmissionEntity;
  @Prop({required: true}) private company!:CompanyEntity;

  private isUpdating:boolean = false;
  private selfSubmitters:SpecSelfSubmitterEntity[] = [];

  private formData:ISpecSelfSubmissionUpdateRequest = {
    price: this.selfSubmission.price,
    submitterId: this.selfSubmission.meta.specSelfSubmitterId,
    originalSpecAttachment: this.selfSubmission.originalSpecAttachment,

    departmentIds: this.selfSubmission.specAcceptance.departments.map(d => d.id),
    operatorId: this.selfSubmission.specAcceptance.operatorId,
    receiverIntraCode: this.selfSubmission.specAcceptance.receiverIntraCode,
    receiverMemo: this.selfSubmission.specAcceptance.receiverMemo,
    intraCustomValues: this.selfSubmission.specAcceptance.intraCustomValues,
    tags: this.selfSubmission.specAcceptance.tags,
  };
  private readonly ValidatorRules = {
    price: [typeNumberRule],
    submitterId: [requiredRule],
    ...SpecAcceptanceValidatorRules
  };
  private readonly PopoverText = PopoverText;

  private get companyId() { return this.selfSubmission.spec.company.id; }

  private audits:AuditEntity[]|null = null;

  private async created() {
    new SpecSelfSubmitterRepository(this.companyId).findAll().then(list => {
      this.selfSubmitters = list;
    });
    this.load();
  }
  private load() {
    (new SpecSelfSubmissionRepository(this.companyId)).getAuditLogs(this.selfSubmission.id).then(data => {
      this.audits = data.sort((a:AuditEntity, b:AuditEntity) => b.date.getTime() - a.date.getTime());
    });
  }

  private async update () {
    if (!(await ValidateWithScroll((this.$refs as any).form.$refs.form))) return;

    LoadingHandler(async () => {
      await (new SpecSelfSubmissionRepository(this.companyId)).updateInfo(this.selfSubmission.id, this.serialize());
    }).then(() => {
      this.$message({type: 'success', message: this.$t(`更新しました`)});
      this.isUpdating = false;
      this.load();
      this.$emit('updated');
    });
  }

  private serialize() {
    const data = cloneDeep(this.formData) as ISpecSelfSubmissionUpdateRequest;
    data.intraCustomValues = data.intraCustomValues.filter(d => !isCustomValueEmpty(d));
    return data;
  }
}
