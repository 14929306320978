


















































import { Vue, Component, Prop} from 'vue-property-decorator';
import {createMinRule, requiredRule} from "@/utils/validation-rules";
import {ValidateWithScroll} from "@/utils/validation-handler";
import {
  ExportDataType,
  ExportRequestPitsOptionPrintPage,
  ExportRequestPitsOptionAmountVisibility,
  ExportRequestPitsOptionPrintPageDict,
  ExportRequestPitsOption
} from "@/repositories/spec/company/spec-repository";
import { help as PopoverText } from '@/lang/help/spec-create';

const SpecExportModalPitsSettingStoreKey = 'spec-export-modal-pits-setting-store-key';

@Component({})
export default class extends Vue {
  @Prop({required: true}) private specName!:string;
  @Prop({default: false}) private needReceiverName!:boolean;

  private readonly DataType = ExportDataType;
  private readonly ExportRequestPitsOptionPrintPage = ExportRequestPitsOptionPrintPage;
  private readonly ExportRequestPitsOptionPrintPageDict = ExportRequestPitsOptionPrintPageDict;
  private readonly ExportRequestPitsOptionAmountVisibility = ExportRequestPitsOptionAmountVisibility;
  private readonly PopoverText = PopoverText;

  private visible:boolean = true;

  private printData = {
    arg1: '',
    pitsOption: {
      printPages: Object.keys(ExportRequestPitsOptionPrintPageDict),
      amountVisibility: ExportRequestPitsOptionAmountVisibility.VISIBLE_ALL,
      showPageNum: true,
    } as ExportRequestPitsOption,
  };

  private printDataRule = {
    arg1: [ requiredRule,],
    pitsOption: {
      printPages: [ requiredRule, createMinRule(1, 'array' )],
      amountVisibility: [ requiredRule ],
      showPageNum: [ requiredRule, ]
    }
  };

  private created() {
    this.restoreSetting();
  }

  private onPrintPagesChanged() {
    // sort
    this.printData.pitsOption.printPages = Object.keys(ExportRequestPitsOptionPrintPageDict)
      .filter((k) => this.printData.pitsOption.printPages.includes(k as ExportRequestPitsOptionPrintPage))
      .map((k) => k as ExportRequestPitsOptionPrintPage);
  }

  private async submit() {
    if (!(await ValidateWithScroll(this.$refs.printModalForm as any))) return;
    this.storeSetting();
    this.$emit('submit', this.printData );
  }

  private restoreSetting() {
    const res = localStorage.getItem(SpecExportModalPitsSettingStoreKey);
    if (res) {
      const json = JSON.parse(res);
      console.debug(json);
      this.printData.pitsOption.printPages = json.printPages;
      this.printData.pitsOption.amountVisibility = json.amountVisibility;
      this.printData.pitsOption.showPageNum = json.showPageNum || true;
    }
  }
  private storeSetting() {
    localStorage.setItem(SpecExportModalPitsSettingStoreKey, JSON.stringify(this.printData.pitsOption));
  }

  private get labels(): {title: string; body: string; action: string;} {
    return {
      title: this.$t('食品規格書'),
      body: this.$t('印刷のための条件を設定してください。印刷準備には数十秒かかることがございます。なお、印刷して提出してしまうと、規格書の更新のたびに提出先の管理が必要となってしまうため、提出先に「スマート食品規格書」を導入していただいた上で、システム上で送信することをオススメ致します。'),
      action: this.$t('印刷'),
    }
  }
}
