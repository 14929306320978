




























































































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  FoodTypeDict, BrandTypes, ExpirationFormatTypeMap,
  ValidatorRules, SpecCreateBaseEntity, ExpirationTypeEnum, PackingFormWeightUnitTypeDict
} from "@/entities/specs/spec-entity";
import SpecPackingFormEntity, {
  PackingUnitTypeEnum,
  PackingUnitTypes
} from "@/entities/specs/spec-packing-form-entity";
import { help as PopoverText } from '@/lang/help/spec-create';
import JicfsCategoryRepository, {JicfsCategory} from "@/repositories/master/jicfs-category-repository";
import {Units} from '@/entities/unit-entities';
import {Alcohols} from "@/repositories/spec/alcohol-repository";
import AddDeleteTable from "@/components/Table/AddDeleteTable.vue";
import SpecCertificationEntity from "@/entities/specs/spec-certification-entity";
import ExpirationTypeAndDays from "./components/ExpirationTypeAndDays.vue";
import StorageTemperatureRange from "./components/StorageTemperatureRange.vue";
import CustomValueCreateSection from "@/views/spec/companies/components/spec-components/create/components/CustomValueCreateSection.vue";
import { SpecCustomValueCategory } from '@/entities/specs/spec-custom-value-entity';
import SpecMetaAndVersionSelect, {SpecMetaAndVersionSelectModelType} from "@/views/spec/companies/components/SpecMetaAndVersionSelect.vue";
import SpecReferringSpecEntity from "@/entities/specs/spec-referring-spec-entity";
import ReferableSpecRepository from "@/repositories/spec/company/referable-spec-repository";
import LoadingHandler from "@/utils/loading-handler";
import SpecImExportError, {
  SpecImportErrorPropType
} from "@/entities/specs/spec-im-export-error";
import BooleanSelect from "@/components/Form/BooleanSelect.vue";
import CompanyEntity from "@/entities/company-entity";
import CompanySpecCustomValueSettingEntity from "@/entities/company-spec-custom-value-setting-entity";

@Component({
  components: {
    BooleanSelect,
    SpecMetaAndVersionSelect,
    CustomValueCreateSection,
    AddDeleteTable,
    ExpirationTypeAndDays,
    StorageTemperatureRange
  },
})
export default class SpecCreateBasic extends Vue {
  @Prop({required: true}) private company!:CompanyEntity;
  @Prop({required: false}) private customValueSettings?:CompanySpecCustomValueSettingEntity[];
  @Prop({required: true}) private model!:SpecCreateBaseEntity;

  @Prop({required: false}) private convertingError?:SpecImExportError;

  private readonly PopoverText = PopoverText;
  private readonly rules = ValidatorRules;

  private readonly FoodTypeDict = FoodTypeDict;
  private readonly BrandTypes = BrandTypes;
  private readonly ExpirationFormatTypeMap = ExpirationFormatTypeMap;
  private readonly PackingUnitTypeEnum = PackingUnitTypeEnum;
  private readonly PackingUnitTypes = PackingUnitTypes;
  private readonly Units = Units;
  private readonly Alcohols = Alcohols;
  private readonly SpecCustomValueCategory = SpecCustomValueCategory;
  private readonly ExpirationTypeEnum = ExpirationTypeEnum;
  private readonly PackingFormWeightUnitTypeDict = PackingFormWeightUnitTypeDict;

  private readonly SpecImportErrorPropType = SpecImportErrorPropType;

  private jicfsCategories:JicfsCategory[] = [];


  private referenceSpec: SpecMetaAndVersionSelectModelType = !!this.model.referringSpec ? {
    meta: { id: this.model.referringSpec!.referredMetaId, isAcceptance: this.model.referringSpec!.isAcceptance },
    versionId: this.model.referringSpec!.referredId,
  } : {
    meta: null,
    versionId: null
  }

  private async referSpec() {
    await LoadingHandler(async () => {
      if (!this.referenceSpec.meta || !this.referenceSpec.versionId) return;
      const referringModel = (await (new ReferableSpecRepository(this.company.id)).findSpec(this.referenceSpec.meta.isAcceptance, this.referenceSpec.versionId)).toSpecUpdateEntity();
      referringModel.referringSpec = new SpecReferringSpecEntity({
        referredMetaId: this.referenceSpec.meta.id,
        referredSpecId: this.referenceSpec.meta.isAcceptance ? null : this.referenceSpec.versionId,
        referredSpecAcceptanceSpecId: this.referenceSpec.meta.isAcceptance ? this.referenceSpec.versionId : null,
      })
      referringModel.$hasReferringSpec = true;
      this.$emit('import-spec', referringModel);
    });
  }

  private getImExportingError(prop:string): string {
    if (!this.convertingError || this.convertingError.specErrors.length === 0) return '';
    const err = this.convertingError.specErrors.find(e => e.prop === prop);
    if(!err) return '';
    return err.message!;
  }

  private created() {
    if(!this.model.certifications!.length) {
      this.addCertificationRow();
    }

    (new JicfsCategoryRepository()).list().then(list => {
      this.jicfsCategories = list;
    });

    if (!this.model.packingForms || this.model.packingForms.length === 0) {
      this.model.packingForms = Object.keys(this.PackingUnitTypes).map(put => {
        const active = [
          PackingUnitTypeEnum.Piece,
          PackingUnitTypeEnum.Ball,
          PackingUnitTypeEnum.Case,
        ].includes(Number(put));

        return new SpecPackingFormEntity({
          type: Number(put),
          active: active
        });
      });
    }
    if (!this.model.packingFormPiece) {
      this.model.packingForms.push( new SpecPackingFormEntity({ type: PackingUnitTypeEnum.Piece, active: true }));
    }
    this.$emit('initialized');
  }

  private addCertificationRow() {
    this.model.certifications!.push(new SpecCertificationEntity());
  }

  private onPackingFormChanged() {
    this.model.packingSpec = this.model.createPackingFormLabel();
  }
}
