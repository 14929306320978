














































import {Component, Vue, Prop} from 'vue-property-decorator';

import IngredientItemEntity, {IngredientItemValidatorRules} from "@/entities/ingredient-item-entity";
import { help as PopoverText } from '@/lang/help/ingredient-create';
import {AllergenList, IAllergen} from "@/entities/allergy-entity";

@Component
export default class extends Vue {
  @Prop({default: false}) private singleSelection!:boolean;
  @Prop({required: true}) private ingredientItems!:IngredientItemEntity[];
  @Prop({default: false}) private disabled!:boolean;

  private PopoverText = PopoverText;
  private rules = IngredientItemValidatorRules;

  private allergens = AllergenList;

  // 表示除外のアレルゲンが、構成原材料のアレルゲンに含まれなくなったら、選択を解除する
  private onIngredientItemAllergenChanged(ingredientItem) {
    if (!ingredientItem.allergenIdToOmitDisplay) return;

    if(!this.getAllergensSelected(ingredientItem).includes(ingredientItem.allergenIdToOmitDisplay)) {
      ingredientItem.allergenIdToOmitDisplay = null;
    }
  }
  private getAllergensSelected(ingredientItem) {
    return this.allergens!.filter(a => ingredientItem.allergyIds.includes(a.id));
  }

  public get isAnyAllergenSelected() {
    return this.ingredientItems.some(ii => ii.allergyIds.length > 0);
  }
}
