<template>
  <div>
    <div class="page-spec-show__sidenav">
      <router-link :to="{name: `${routePrefix}.show`}"
                   :class="{active: $route.name === `${routePrefix}.show`}" class="page-spec-show__sidenav__item">{{ $t('基本') }}</router-link>
      <router-link :to="{name: `${routePrefix}.show.company`}"
                   :class="{active: $route.name === `${routePrefix}.show.company`}" class="page-spec-show__sidenav__item">{{ $t('企業') }}</router-link>
      <router-link :to="{name: `${routePrefix}.show.ingredient`}"
                   :class="{active: $route.name === `${routePrefix}.show.ingredient`}" class="page-spec-show__sidenav__item">{{ $t('原材料') }}</router-link>
      <router-link :to="{name: `${routePrefix}.show.label`}"
                   :class="{active: $route.name === `${routePrefix}.show.label`}" class="page-spec-show__sidenav__item">{{ $t('一括表示') }}</router-link>
      <router-link :to="{name: `${routePrefix}.show.nutrition`}"
                   :class="{active: $route.name === `${routePrefix}.show.nutrition`}" class="page-spec-show__sidenav__item">{{ $t('栄養成分') }}</router-link>
      <router-link :to="{name: `${routePrefix}.show.package`}"
                   :class="{active: $route.name === `${routePrefix}.show.package`}" class="page-spec-show__sidenav__item">{{ $t('包材') }}</router-link>
      <router-link :to="{name: `${routePrefix}.show.manufacture`}"
                   :class="{active: $route.name === `${routePrefix}.show.manufacture`}" class="page-spec-show__sidenav__item">{{ $t('製造') }}</router-link>
    </div>
    <div v-if='showIntra' class="page-spec-show__sidenav u-mt20">
      <router-link :to="{name: `${routePrefix}.show.intra`}"
                   :class="{active: $route.name === `${routePrefix}.show.intra`}" class="page-spec-show__sidenav__item">{{ $t('管理情報') }}</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      routePrefix: {required: true},
      showIntra: {type: Boolean, default: true},
    },
  }
</script>
