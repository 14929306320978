































import { Component, Vue, Prop } from 'vue-property-decorator';
import {IFileDefaultValue} from "@/contracts/i-file";
import { help as PopoverText } from '@/lang/help/spec-create';
import {FoodType} from "@/entities/specs/spec-entity";
import {FoodTypeDict} from "@/entities/specs/spec-entity";
import SimpleFormItem from "@/components/Form/SimpleFormItem.vue";

@Component({
  components: {SimpleFormItem}
})
export default class extends Vue {
  @Prop({type: Boolean}) value!: boolean;

  private readonly PopoverText = PopoverText;
  private readonly FoodTypeDict = FoodTypeDict;

  private get visible() { return this.value; };
  private set visible(val) { this.$emit('input', val); };

  private model = {
    file: IFileDefaultValue,
    foodType: FoodType.Process,
  };

  private get canSubmit() {
    return !!this.model.foodType && !!this.model.file.body;
  }

  private async submit() {
    if (!this.canSubmit) return;

    this.$emit('submit', this.model);
    this.visible = false;
  }
}
