


















import { Component, Vue, Prop } from 'vue-property-decorator';
import SpecEntity, {ExpirationTypeEnum, ExpirationTypes, SpecCreateBaseEntity} from "@/entities/specs/spec-entity";

@Component({
})
export default class ExpirationTypeAndDays extends Vue {
  @Prop({required: true}) private model!: SpecCreateBaseEntity;
  @Prop({default: true}) private typeEnabled!: boolean;
  @Prop({required: true}) private daysProp!: string;
  @Prop({required: false}) private daysError!: string;

  private readonly ExpirationTypeEnum = ExpirationTypeEnum;
  private readonly ExpirationTypes = ExpirationTypes;

  private get isTypeValid() {
    return this.model.expirationType !== ExpirationTypeEnum.None;
  }
}

